<template>
    <div>
        <v-row
            class="p-0"
            v-for="(category, indexC) of categories"
            :key="indexC"
        >
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-card-title class="pl-1 text-capitalize">
                        {{ category }}
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="indexC == 0"
                            :disabled="!valid"
                            rounded
                            color="primary"
                            @click="updatePermissions"
                            :loading="loading"
                            >SAVE
                        </v-btn>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        class="elevation-0"
                        :items="permissions[category] || []"
                        :mobile-breakpoint="0"
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:[`item.able`]="{ item }">
                            <v-simple-checkbox
                                :key="item.permission"
                                color="success"
                                v-model="item.able"
                                @click="onCheckboxClicked(item)"
                            ></v-simple-checkbox>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import API from '@/services/api'
export default {
    name: 'UserPermissions',
    props: {
        id: String,
        user: Object,
    },
    data() {
        return {
            loading: false,
            loadingError: false,
            errorMsg: null,
            valid: false,
            newUser: null,
            headers: [
                {
                    text: 'NAME',
                    value: 'name',
                    width: '20%',
                    class: 'grey lighten-3',
                    sortable: false,
                },
                {
                    text: 'DESCRIPTION',
                    value: 'description',
                    width: '70%',
                    class: 'grey lighten-3',
                    sortable: false,
                },
                {
                    text: 'ABLE',
                    value: 'able',
                    sortable: false,
                    width: '10%',
                    class: 'grey lighten-3',
                },
            ],
            permissions: {},
            categories: [],
        }
    },
    watch: {
        user: function() {
            this.setPermissions()
        },
    },
    computed: {
        ...mapState(['companyRoles', 'usersPermissions', 'settings']),
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onCheckboxClicked: function(item) {
            if (item.able) {
                item.able = true
            } else {
                item.able = false
            }
            this.permissions = { ...this.permissions }
            this.valid = true
        },
        updatePermissions: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                var arrayPermissions = []
                Object.keys(this.permissions).map(key => {
                    for (const permission of this.permissions[key]) {
                        if (permission.able) {
                            arrayPermissions.push(permission.permission)
                        }
                    }
                })
                const {
                    data: { user },
                } = await API.updatePermissions(this.id, arrayPermissions)
                this.newUser = user
                this.user.permissions = this.newUser.permissions
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        setPermissions() {
            const setting = this.settings.find(s => s.name == 'Permissions')
            this.categories = Object.keys(setting.permissions)
            for (const category of this.categories) {
                this.permissions[category] = setting.permissions[category]
                for (const permission of this.permissions[category]) {
                    if (this.user.permissions.includes(permission.permission)) {
                        permission.able = true
                    } else {
                        permission.able = false
                    }
                }
            }
            this.permissions = { ...this.permissions }
        },
    },
    mounted() {
        if (this.user) {
            this.setPermissions()
        }
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.no-border {
    border: none !important;
}
tr:hover {
    background: unset !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
</style>
